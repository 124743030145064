<template>
	<div
		ref="login"
		class="max-w-xl m-auto"
	>
		<div class="w-full h-full">
			<div class="relative -mt-4 bg-white md:hidden rounded-bl-4xl force-w-screen xs:min-h-40 min-h-56">
				<div
					v-if="true"
					class="ocean overflow-x-clip"
				>
					<div class="overflow-hidden wave" />
					<div class="overflow-hidden wave" />
				</div>
				<router-link
					to="/"
					title="Home"
					class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/3 left-1/2 w-1/4 z-10 max-w-[115px]"
				>
					<WwLogo size="half" />
				</router-link>
				<AsymmetricWave class="absolute bottom-0 z-0 text-white" />
			</div>
			<h1 class="pt-0 text-2xl font-bold md:text-3xl md:p-2">
				Welcome back!
			</h1>
			<p class="text-base md:text-xl">
				Please login to continue using {{ APP_META.NAME }}
			</p>
			<router-link
				v-if="isLoginPage"
				to="/join"
				class="md:hidden before:content-['or'] before:text-black before:font-normal before:hover:no-underline text-sm text-green-500 font-semibold hover:underline underline-offset-2 text-center w-full"
			>
				join the community
			</router-link>
			<form
				id="loginForm"
				class="flex flex-col mt-12 text-left xs:mt-6 md:mt-16 lg:mt-20 selection:bg-green-500 selection:text-white"
				@submit.prevent="handleLogin"
			>
				<div class="grid grid-cols-1 xs:gap-y-8 gap-y-6 md:gap-y-8 md:pb-2 min-h-36">
					<WwTextInput
						id="username"
						v-model="username"
						:value="username"
						name="username"
						required
						placeholder="johndoe@123.xyz"
						input-class="w-full"
						autocomplete="username"
						:state="inputFieldState(username, TEXT)"
						label="Username"
						floating-label
						border-class="border-b-2"
					/>
					<WwTextInput
						id="password"
						v-model="password"
						:value="password"
						name="password"
						:input-type="PASSWORD"
						:required="true"
						input-class="w-full"
						autocomplete="password"
						:state="inputFieldState(password, PASSWORD)"
						label="Password"
						placeholder="wdSkD@123!ds"
						floating-label
						border-class="border-b-2"
					/>
				</div>
				<div class="flex flex-col items-start justify-between pt-3 pb-8 sm:flex-row sm:items-center">
					<div class="flex items-center space-x-2 md:py-2">
						<input
							type="checkbox"
							class="w-5 h-5 text-green-500 border border-gray-300 rounded-sm appearance-none focus:ring focus:ring-green-300 hover:bg-gray-200"
						>
						<p>
							Remember me
						</p>
					</div>
					<router-link to="/forgot">
						<span class="font-bold hover:text-green-500">
							Forgot your password?
						</span>
					</router-link>
				</div>
				<WwSubmitButton
					:disabled="isDisabled"
					:is-loading="isLoading"
					button-text="Login"
				/>
			</form>
			<SocialLoginButtons
				v-if="isMounted"
				class="w-full mx-auto"
				@logged-in="handleLoginClose"
			/>
		</div>
	</div>
</template>

<script async>
import { mapActions, mapMutations, mapState } from 'vuex'

import AsymmetricWave from '@/components/icons/AsymmetricWave.vue'
import WwLogo from '@/components/multiUse/WwLogo.vue'
import WwSubmitButton from '@/components/UI/buttons/WwSubmitButton.vue'
import WwTextInput from '@/components/UI/WwTextInput.vue'
import { APP_META } from '@/constants/index.js'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { PASSWORD, TEXT } from '@/constants/text-inputs/types.js'
import { logError } from '@/utils/error-handling.js'
import { inputFieldState } from '@/validators/text-inputs.js'

export default {
	components: {
		SocialLoginButtons: () => import('@/components/login/SocialLoginButtons.vue'),
		WwTextInput,
		WwSubmitButton,
		AsymmetricWave,
		WwLogo
	},
	props: {
		isModal: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			username: '',
			password: '',
			APP_META,
			PASSWORD,
			TEXT,
			inputFieldState,
			isLoading: false,
			height: 0,
			isMounted: false
		}
	},
	computed: {
		...mapState({
			lastRoute: (state) => { return state.lastRoute }
		}),
		routeTo() {
			switch (this.lastRoute) {
				case '/login':
					return '/'
				case '':
					return '/'
				default:
					return this.lastRoute
			}
		},
		isDisabled() {
			return this.username.length === 0 || this.password.length === 0
		},
		isLoginPage() {
			return this.$route.name === 'login'
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		...mapMutations('modal', [ 'closeModal' ]),
		...mapMutations('toast', [ 'showToast' ]),
		...mapActions('auth', [ 'logIn' ]),
		handleLoginClose() {
			if (this.isModal) {
				this.closeModal()
			} else {
				this.$router.push(this.routeTo)
			}
		},
		async handleLogin() {
			try {
				this.isLoading = true
				await this.logIn({
					username: this.username,
					password: this.password
				})
				await Array.from(this.$apollo.provider.defaultClient.queryManager.queries).map(([ key, value ]) => value)
					.find(query => query.observableQuery?.queryName === 'GetCarts')
					.observableQuery
					.refetch()
			} catch (e) {
				logError(e)
			} finally {
				this.isLoading = false
			}
		},
		fieldState(value) {
			return value?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		}
	}
}
</script>

<style type="text/css">
	.ocean {
		height: 5%;
		width:100%;
		position:absolute;
		bottom:0;
		left:0;
		background: #ffffff;
	}
	.wave {
		background: url('@/assets/icons/wave.svg') repeat-x;
		position: absolute;
		top: -198px;
		width: 6400px;
		height: 198px;
		animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
		transform: translate3d(0, 0, 0);
	}
	.wave:nth-of-type(2) {
		top: -175px;
		animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
		opacity: 1;
	}
	@keyframes wave {
		0% {
			margin-left: 0;
		}
		100% {
			margin-left: -1600px;
		}
	}
	@keyframes swell {
		0%, 100% {
			transform: translate3d(0,-30px,0);
		}
		50% {
			transform: translate3d(0,-20px,0);
		}
	}
</style>
